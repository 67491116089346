import React, { useEffect, useState } from 'react'
import '../../App.css'
import MainLayout from '../../Layouts/MainLayout'
import {
  Table,
  Icon,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  TextArea,
  ModalFooter,
  Select
} from 'design-react-kit'

import { useParams, useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import listAPI from '../../Apis/EnumAPI'
import { GenericAPI } from '../../Apis/GenericAPI'
import dayjs from 'dayjs'

export default function IndexServiceRequestMessage(props) {
  let params = useParams()

  const navigate = useNavigate()
  const [requests, setRequests] = useState(null)
  const [header, setHeader] = useState('')
  const [user, setUser] = useState('')
  const [value, setValue] = useState(null)
  const [saved, setSaved] = useState({
    id: '',
    status: '',
    message: ''
  })
  const [search, setSearch] = useState('')
  const [username, setUsername] = useState('')
  const [id, setId] = useState('')
  const [practice_n, setPractice_n] = useState('')
  const [message, setMessage] = useState('')
  const [date, setDate] = useState('')
  const [dataDisservizio, setDataDisservizio] = useState('')
  const [searchData, setSearchData] = useState({})
  //Modifica mensa e trasporto
  const [open, toggleModal] = useState(false)
  const toggle = () => toggleModal(!open)
  const openModal = (id, date, message) => {
    setId(id)
    setDate(date)
    setMessage(message)
    toggleModal(true)
  }

  const defaultOptions = [
    { value: 'IN_PROGRESS', label: 'in corso' },
    { value: 'TO_COMPLETE', label: 'da completare' },
    { value: 'DRAFT', label: 'in bozza' },
    { value: 'WAITING', label: 'in attesa' },
    { value: 'ERROR', label: 'rifiutata' },
    { value: 'COMPLETED', label: 'conclusa' }
  ]
  const defaultServices = [
    { value: 'SPA', label: 'Prenotazione appuntamento' },
    { value: 'SRA', label: 'Richiesta di assistenza' },
    { value: 'SSD', label: 'Segnalazione disservizio' },
    { value: 'RMS', label: 'Richiesta mensa scolastica' },
    { value: 'PTI', label: 'Pagamento IMU - F24' },
    { value: 'RTS', label: 'Richiesta trasporto scolastico' },
    { value: 'PSP', label: 'Occupazione suolo pubblico' },
    { value: 'PCO', label: 'Pagamento contravvenzioni' },
    { value: 'RAAT', label: 'Richiesta accesso agli atti' },
    { value: 'PPI', label: 'Richiedere permesso di parcheggio invalidi' },
    { value: 'RPM', label: 'Richiesta pubblicazione di matrimonio' },
    { value: 'DAS', label: 'Presentazione domanda per agevolazioni scolastiche' },
    { value: 'DAT', label: 'Presentazione domanda per agevolazioni tributarie' },
    { value: 'DBE', label: 'Presentazione domanda per bonus economici' },
    ]
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem('configuration')) {
      const config = JSON.parse(localStorage.getItem('configuration'))
      setHeader(config.data.current)
    }
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      setUser(user)
      console.log(user)
    }
    getRequest()
  }, [])

  const handleChange = e => {
    setValue(e.target.options[e.target.selectedIndex].value)
  }

  const getRequest = async () => {
    try {
      const response = await GenericAPI.get(
        listAPI.ListServiceRequestMessage,
        params.service_id
      )
      setRequests(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  //ho creato questa funzione per recuperarmi la label in italiano, modifica pure se non va bene, è funzionante
  function findLabelByValue(value) {
    const option = defaultServices.find(option => option.value === value)
    return option ? option.label : ''
  }

  const save = async () => {
    try {
      //const selectedLabel = findLabelByValue(value);

      //console.log(selectedLabel);
      await GenericAPI.save({
        status: value,
        id: saved.id,
        message: saved.message
      })
      toggle()
      getRequest()
      //window.location.reload(true)
    } catch (err) {
      console.log(err)
    }
  }

  const messageSaving = e => {
    //console.log(e.target.value);
    setSaved(prev => {
      return {
        ...prev,
        message: e.target.value,
        id: e.target.getAttribute('data-protocollo')
      }
    })
  }

  const onChangeSearch = e => {
    setSearch(e.target.value)
  }
  //non utlizzata
  function extractCode(str) {
    if (str !== null && str !== undefined) {
      const frase = str.toLowerCase()
      const contieneRMS = frase.includes('rms')
      if (contieneRMS) {
        const regex = /RMS-(\d+)/
        const match = str.match(regex)
        if (match) {
          const codiceRMS = match[1]
          return 'RMS-' + codiceRMS
        } else {
          return ''
        }
      } else {
        const regex = /RTS-(\d+)/
        const match = str.match(regex)
        if (match) {
          const codiceRMS = match[1]
          return 'RTS-' + codiceRMS
        } else {
          return ''
        }
      }
    }
  }
  function extractPayment(str) {
    if (str !== null && str !== undefined) {
      const regex = /n\. ([a-zA-Z0-9]+)/
      const match = str.match(regex)
      if (match) {
        const codicePagamento = match[1]
        return codicePagamento
      } else {
        return ''
      }
    }
  }

  const onSearch = async e => {
    try {
      const res = await GenericAPI.search_message(params.service_id, search)
      //console.log(params.practice_number);
      console.log(res.data)
      setRequests(res.data)
      setSearchData(res.data)
      //console.log(data);
      //usa setSearchData() per salvarci quello che ti ritorna l'api search
      //dsadsa
    } catch (err) {
      console.log(err)
    }
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToCsv = e => {
    e.preventDefault()

    // Headers for each column
    let headers = ['Id,Protocollo,Data,Titolo,Sotto titolo,Messaggio']
    //let practice_number = requests?.practice_number
    // Convert users data to a csv
    const usersCsv = requests?.reduce((acc, user) => {
      const { id, request, date, title, sub_title, message } = user
      acc.push(
        [
          id,
          request?.practice_number !== null &&
            request?.practice_number !== undefined
            ? request?.practice_number
            : extractPayment(message),
          date,
          title,
          sub_title,
          message
        ].join(',')
      )
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'Messaggi-' + findLabelByValue(params.service_id) + '.csv',
      fileType: 'text/csv'
    })
  }

  const indietro = () => {
    navigate(-1)
  }

  return (
    <MainLayout auth={user} errors={props.errors} header={header}>
      <Container className='my-5'>
        <Button
          style={{
            marginBottom: '50px',
            textAlign: 'center',
            whiteSpace: 'nowrap'
          }}
          onClick={indietro}
          tabIndex='-1'
          color='primary'
          size='xs'
          icon
        >
          <Icon icon='it-arrow-left' color='white' />
          Torna alla lista dei servizi
        </Button>
        <h2 className='fw-normal mb-5'>
          Messaggi - {findLabelByValue(params.service_id)}
        </h2>
        <div
          style={{ marginTop: '20px', marginBottom: '20px', display: 'flex' }}
        >
          <input
            type='text'
            id='searchBar'
            className='search'
            placeholder={'Ricerca per numero di pratica'}
            onChange={onChangeSearch}
          />
          <input
            className='search-btn'
            type='button'
            value='&#9740;'
            onClick={onSearch}
          />
          <Button
            style={{
              textAlign: 'center',
              whiteSpace: 'nowrap'
            }}
            onClick={exportToCsv}
            tabIndex='-1'
            color='primary'
            size='xs'
            icon
          >
            <Icon icon='it-download' color='white' />
            Scarica CSV
          </Button>
        </div>

        <main>
          {requests == null ? (
            <Spinner active label />
          ) : (
            <div
              style={{ maxHeight: '800px', overflow: 'auto' }}
              className='py-12'
            >
              <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
                <div className='bg-white overflow-hidden shadow-sm sm:rounded-lg'>
                  <div className='p-6 bg-white border-b border-gray-200'>
                    <div className='flex items-center justify-between mb-6'></div>

                    <Table striped>
                      <thead>
                        <tr>
                          <th scope='col'>Id</th>
                          <th scope='col'>N° pratica</th>
                          <th scope='col'>Tipo messaggio</th>
                          <th scope='col'>Data messaggio</th>
                          {(params.service_id === 'RMS') |
                            (params.service_id === 'RTS') |
                            (params.service_id === 'PCO') |
                            (params.service_id === 'PSP')  |
                            (params.service_id === 'RAAT')|
                            (params.service_id === 'PPI') |
                            (params.service_id === 'RPM') |
                            (params.service_id === 'DAS') |
                            (params.service_id === 'DAT') |
                            (params.service_id === 'DBE')? (
                            //posso usare anche <React.Fragment>... </React.Fragment>
                            <>
                              <th scope='col'>Dettagli</th>
                            </>
                          ) : (
                            ''
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {requests?.map(
                          ({
                            id,
                            register,
                            message,
                            date,
                            created_at,
                            practice_number,
                            messageShort,
                            sub_title,
                            request
                          }) => (
                            <tr key={Math.random() * 31 * Math.random() * 11}>
                              <th scope='row'>{id}</th>
                              <td scope='row'>
                                {request?.practice_number !== null &&
                                  request?.practice_number !== undefined
                                  ? request?.practice_number
                                  : practice_number |
                                    (request?.practice_number !== null) &&
                                    request?.practice_number !== undefined
                                    ? request?.practice_number
                                    : extractPayment(messageShort)}
                              </td>{' '}
                              <td>{sub_title.split(' ')[0]}</td>
                              {/*TESTARE*/}
                              <td>{date}</td>
                              {(params.service_id === 'RMS') |
                                (params.service_id === 'RTS') |
                                (params.service_id === 'PCO') |
                                (params.service_id === 'PSP') |
                                (params.service_id === 'RAAT')|
                                (params.service_id === 'PPI')|
                                (params.service_id === 'RPM')|
                                (params.service_id === 'DAS')|
                                (params.service_id === 'DAT')|
                                (params.service_id === 'DBE')? (
                                <>
                                  <td>
                                    <>
                                      <Button
                                        onClick={() =>
                                          //TODO mostrare i messaggi come cheisto
                                          openModal(id, date, message)
                                        }
                                        id={id}
                                        tabIndex='-1'
                                        color='primary'
                                        size='xs'
                                        icon
                                      >
                                        <Icon
                                          icon='it-info-circle'
                                          color='white'
                                        />
                                      </Button>
                                    </>
                                  </td>
                                </>
                              ) : (
                                ''
                              )}
                            </tr>
                          )
                        )}
                        {requests?.length === 0 && (
                          <tr>
                            <td colSpan='6'>No request found.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </Container>
      {/* mensa e trasporto */}
      <Modal fade centered isOpen={open} toggle={toggle} labelledBy='esempio14'>
        <ModalHeader toggle={toggle} id='esempio14'>
          DETTAGLI
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <td>{message}</td>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </MainLayout>
  )
}
