import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GenericAPI } from "../Apis/GenericAPI";
import listAPI from "../Apis/EnumAPI";

const ProtectedRoute = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [config, setConfig] = useState(false);
  const navigate = useNavigate();
 
  const configuration = async (retry = false) => {
    try {
      const response = await GenericAPI.getAll(listAPI.Configuration);
      localStorage.removeItem("configuration");
      localStorage.setItem("configuration", JSON.stringify(response));
      setConfig(response);
    } catch (err) {
      console.log(err);
      if (!retry) {
        setTimeout(() => configuration(true), 1000); // Riprova dopo 1 secondo
      }
    }
  };

  const checkUserToken = () => {
    const userToken = localStorage.getItem("access_token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }

    setIsLoggedIn(true);
  };

  useEffect(() => {
    //console
    const initialize = async () => {
      await configuration(); // Aspetta che la configurazione sia completata
      checkUserToken(); // Poi controlla il token dell'utente
    };

    if (!config) {
      initialize();
    }
  }, [config]);

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default ProtectedRoute;
