const listAPI = Object.freeze({ 
    Services: 'service-details', 
    Login: 'login-operator', 
    Otp: 'otp-request',  
    Configuration: 'configuration',
    Operators: 'operator',
    ListServcieRequest :'list-service-request',
    ListServiceRequestMessage :'list-message',
    ListServiceRequestPayment :'list-payment',
    ServiceRequest : 'service-request',
    DownloadAttach : 'download-attach'
  })

  export default listAPI