import React, { useEffect, useState } from "react";

import { Table, Icon, Button, Spinner } from "design-react-kit";

import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import MainLayout from "./Layouts/MainLayout";
import { GenericAPI } from "./Apis/GenericAPI";
import listAPI from "./Apis/EnumAPI";

export default function Dashboard(props) {
  const [services, setServices] = useState(null);
  const [retryCount, setRetryCount] = useState(0); // Aggiungi stato per contare i tentativi

  const navigate = useNavigate();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [config, setConfig] = useState(null);
  useState(false);

  const modify = (e) => {
    navigate(`/list-service-request/${e.currentTarget.id}`);
  };

  const messages = (e) => {
    navigate(`/list-service-request-message/${e.currentTarget.id}`);
  };
  const payments = (e) => {
    navigate(`/list-service-request-payment/${e.currentTarget.id}`);
  };

  const add = () => {
    navigate("/create-municipality");
  };

  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));

 
      setConfig(config);
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);

    }

    getService();
  }, []);

  const getService = async () => {
    try {
      const response = await GenericAPI.getAll(listAPI.Services);
      if (response.success) {
        setServices(response.data);
        setRetryCount(0); // Reset retry count on success
      } else {
        handleRetry();
      }
    } catch (err) {
      console.log(err);
      handleRetry();
    }
  };

  const handleRetry = () => {
    if (retryCount < 3) {
      setRetryCount(retryCount + 1);
      setTimeout(getService, 2000); // Retry after 2 seconds
    } else {
      setServices([]); // Set services to an empty array to stop spinner
    }
  };

  return (
    <MainLayout auth={user} errors={props.errors} header={header}>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Lista dei servizi </h2>{" "}
        <main>
          {services == null ? (
            <Spinner active label />
          ) : (
            <div className="py-12">
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                  <div className="p-6 bg-white border-b border-gray-200">
                    <div className="flex items-center justify-between mb-6"></div>

                    <Table striped>
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Titolo</th>
                          <th scope="col">Stato</th>
                          <th scope="col">Pratiche</th>
                          <th scope="col">Messaggi</th>
                          <th scope="col">Pagamenti</th>
                        </tr>
                      </thead>
                      <tbody>
                        {services?.map(({ id, title, path, status }) =>
                          id !== "PCL" && id !== "RSD" && id !== "PCCO" ? (
                            <tr key={Math.random() * 31 * Math.random() * 11}>
                              <th scope="row">{id}</th>
                              <td>{title}</td>

                              <td>
                                {" "}
                                {status ? (
                                  <Icon icon="it-check" color="green" />
                                ) : (
                                  <Icon icon="it-ban" color="red" />
                                )}
                              </td>

                              <td>
                                {status && id !== "PCL" && id !== "PCCO" ? (
                                  <Button
                                    onClick={modify}
                                    id={id}
                                    tabIndex="-1"
                                    color="primary"
                                    size="xs"
                                    icon
                                  >
                                    <Icon icon="it-list" color="white" />
                                  </Button>
                                ) : (
                                  null
                                )}
                              </td>
                              <td>
                                {status &&
                                (id === "RMS") |
                                  (id === "RTS") |
                                  (id === "PSP") |
                                  (id === "RAAT") |
                                  (id === "PPI") |
                                  (id === "RPM") |
                                  (id === "DAS") |
                                  (id === "DAT") |
                                  (id === "DBE") ? (
                                  <Button
                                    onClick={messages}
                                    id={id}
                                    tabIndex="-1"
                                    color="primary"
                                    size="xs"
                                    icon
                                  >
                                    <Icon icon="it-list" color="white" />
                                  </Button>
                                ) : (
                                  null
                                )}
                              </td>
                              <td>
                                {status &&
                                (id === "RMS") |
                                  (id === "RTS" && config?.data?.current?.id !== "E070") |
                                  (id === "PTI") |
                                  (id === "PSP") |
                                  (id === "PCO") |
                                  (id === "RAAT" &&
                                    (config?.data?.current?.id !== "F867" &&
                                      config?.data?.current?.id !== "A422")) ? (
                                  <Button
                                    onClick={payments}
                                    id={id}
                                    tabIndex="-1"
                                    color="primary"
                                    size="xs"
                                    icon
                                  >
                                    <Icon icon="it-list" color="white" />
                                  </Button>
                                ) : (
                                  null
                                )}
                              </td>
                            </tr>
                          ) : (
                            null
                          )
                        )}
                        {services?.length === 0 && (
                          <tr>
                            <td colSpan="4">No service found.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </Container>
    </MainLayout>
  );
}
