import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../Layouts/MainLayout";
import {
  Col,
  Form,
  FormGroup,
  FormLabel,
  Row,
  Alert,
  Container,
  Button,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { GenericAPI } from "../../Apis/GenericAPI";
import listAPI from "../../Apis/EnumAPI";

export default function Login() {
  const [showOtp, setShowOtp] = useState(false);

  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const logFormRef = useRef({});
  const logBtnRef = useRef({});

  function getAlert() {
    if (show) {
      return (
        <Alert
          key={variant}
          variant={variant}
          onClose={() => setShow(false)}
          dismissible
        >
          <p>{message}</p>
        </Alert>
      );
    }
  }

  const submitLoginForm = (event) => {
    event.preventDefault();

    const formData = new FormData(logFormRef.current);

    const formDataJSON = Object.fromEntries(formData);

    logBtnRef.current.value = "Please wait...";
    logBtnRef.current.setAttribute("disabled", true);

    (async () => {
      try {
        if (!formDataJSON.otp) {
          await GenericAPI.post(listAPI.Otp, formDataJSON).then((response) => {
            if (response.success) {
              setShowOtp(true);
              logBtnRef.current.value = "Login";
              logBtnRef.current.removeAttribute("disabled");
            } else {
              setVariant("danger");
              setMessage("Operazione non riuscita!! " + response.message);
              setShow(true);

              setTimeout(() => {
                setShow(false);
              }, 5000);
            }
          });
        } else {
          await GenericAPI.post(listAPI.Login, formDataJSON).then(
            (response) => {
              if (response.success) {
                logBtnRef.current.value = "Login";
                logBtnRef.current.removeAttribute("disabled");
                const token = response.data.access_token;
                const user = response.data.user;

                if (!token) {
                  setVariant("danger");
                  setMessage("Operazione non riuscita!! " + response.message);
                  setShow(true);

                  setTimeout(() => {
                    setShow(false);
                  }, 5000);
                }

                localStorage.clear();
                localStorage.setItem("access_token", token);
                localStorage.setItem("user", JSON.stringify(user));
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              } else {
                setVariant("danger");
                setMessage("Operazione non riuscita!! " + response.message);
                setShow(true);

                setTimeout(() => {
                  setShow(false);
                }, 5000);
              }
            }
          );
        }
      } catch (error) {
        console.log(error);
        logBtnRef.current.value = "Login";
        logBtnRef.current.removeAttribute("disabled");
        setVariant("danger");
        setMessage("Credenziali errate");
        setShow(true);

        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    })();
  };

  const [header, setHeader] = useState("");

  useEffect(() => {
    configuration();
  }, []);
 
    const configuration = async () => {
      try {
        const response = await GenericAPI.getAll(listAPI.Configuration);
        localStorage.removeItem("configuration");
        localStorage.setItem("configuration", JSON.stringify(response));
        setHeader(response.data.current);
      } catch (err) {
        console.log(err);
      }
    };

  return (
    <MainLayout header={header}>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Login </h2>
        <Row>
          {getAlert()}
          <Col md={{ span: 6 }}>
            <Form ref={logFormRef} id="loginForm" onSubmit={submitLoginForm}>
              <FormGroup className="mb-3">
                <FormLabel htmlFor={"login-username"}> Username </FormLabel>
                <FormCheckInput
                  type={"text"}
                  className="form-control"
                  id={"login-username"}
                  name="email"
                  required
                />
              </FormGroup>
              {showOtp ? (
                <FormGroup className="mb-3">
                  <FormLabel htmlFor={"login-password"}> OTP </FormLabel>
                  <FormCheckInput
                    type={"password"}
                    className="form-control"
                    id={"login-password"}
                    name="otp"
                    required
                  />
                </FormGroup>
              ) : null}
              <Button
                ref={logBtnRef}
                type="submit"
                className="btn-success mt-2"
                id="login-btn"
              >
                
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
