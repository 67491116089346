import React from 'react'
import UserProfile from '../Components/UserProfile'
import LowerHeader from '../UI-component/LowerHeader'

function MainLayout (props) {
  const renderProfile = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    return <UserProfile auth={user} />
  }

  return (
    <div>
      <div className='skiplink'>
        <a className='visually-hidden-focusable' href='#main-container'>
          Vai ai contenuti
        </a>
        <a className='visually-hidden-focusable' href='#footer'>
          Vai al footer
        </a>
      </div>
      <header
        className='it-header-wrapper'
        data-bs-target='#header-nav-wrapper'
      >
        <div className='it-header-slim-wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='it-header-slim-wrapper-content'>
                  <a
                    className='d-lg-block navbar-brand'
                    target='_blank'
                    href='\'
                    aria-label='Vai al portale {Nome della Regione} - link esterno - apertura nuova scheda'
                    title={'Vai al portale del ' + props.header?.country}
                  >
                    {props.header?.country}
                  </a>
                  <div className='it-header-slim-right-zone' role='navigation'>
                    <div className='nav-item dropdown'></div>
                    {renderProfile(props.auth)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LowerHeader description={props.header?.description} links={[]} />
      </header>
      <main>{props.children}</main>
      <footer className='it-footer' id='footer'>
        <div className='it-footer-main'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 footer-items-wrapper logo-wrapper'>
                <div className='it-brand-wrapper'>
                  <a href='http://www.nemeasistemi.com' target='_blank'>
                    <img
                      style={{ width: '13%', padding:'20px' }}
                      aria-hidden='true'
                      src='../assets/bootstrap-italia/dist/svg/nemea-logo-verticale-bianco.png'
                    ></img>
                    <div style={{lineHeight:'2px'}}>
                      <p>
                        <strong>Realizzato da: NeMeA Sistemi Srl</strong>
                      </p>
                      <p>info@nemeasistemi.com</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default MainLayout
