import axios from "axios"

// Crea l'istanza axios per le API
export const api = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_BASE_URL, // Modifica con la tua base URL
});

// Aggiungi un interceptor per includere il token dinamicamente prima di ogni richiesta
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Crea l'istanza axios per il download dei file
export const down = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Modifica con la tua base URL
  responseType: 'arraybuffer',
});

// Aggiungi un interceptor anche per `down` se devi inviare il token anche nelle richieste di download
down.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Definisci un gestore degli errori personalizzato
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // Log degli errori tranne per i 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// Registra il gestore degli errori personalizzato sulle istanze axios
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

down.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
