import { api, down } from "./Configs/AxiosConfigs";

export const GenericAPI = {
  getAll: async function (route) {
    const response = await api.request({
      url: "/" + route + "/",
      method: "GET",
    });
    return response.data;
  },
  get: async function (route, id) {
    const response = await api.request({
      url: "/" + route + "/" + id,
      method: "GET",
    });

    return response.data;
  },
  download: async function (route, id) {
    const response = await down.request({
      url: "/" + route + "/" + id,
      method: "GET",
    });

    return response;
  },
  post: async function (route, item) {
    const response = await api.request({
      url: "/" + route,
      method: "POST",
      data: item,
    });
    return response.data;
  },
  update: async function (route, item) {
    const response = await api.request({
      url: "/" + route + "/" + item.id,
      method: "PUT",
      data: item,
    });
    return response.data;
  },
  delete: async function (route, id) {
    const response = await api.request({
      url: "/" + route + "/" + id,
      method: "DELETE",
    });

    return response.data;
  },

  save: async function (body) {
    const response = await api.request({
      url: "/save-request",
      method: "POST",
      data: body,
    });

    return response.data;
  },

  save_payment: async function (body) {
    const response = await api.request({
      url: "/save-payment",
      method: "POST",
      data: body,
    });

    return response.data;
  },
  add_newPayment: async function (body) {
    const response = await api.request({
      url: "/new-payment",
      method: "POST",
      data: body,
    });

    return response.data;
  },
  add_newDate: async function (body) {
    const response = await api.request({
      url: "/add-new-date",
      method: "POST",
      data: body,
    });

    return response.data;
  },
  add_serviceTime: async function (body) {
    const response = await api.request({
      url: "/add-service-time",
      method: "POST",
      data: body,
    });

    return response.data;
  },

  add_newMulta: async function (body) {
    const response = await api.request({
      url: "/add-new-fine",
      method: "POST",
      data: body,
    });

    return response.data;
  },
  get_serviceTime: async function (body) {
    const response = await api.request({
      url: "/get-service-time/" + body.service_id,
      method: "GET",
    });

    return response.data;
  },
  get_dates: async function () {
    const response = await api.request({
      url: "/get-all-date-not-available",
      method: "GET",
    });

    return response.data;
  },
  deleteDate: async function (id) {
    const response = await api.request({
      url: "/delete-date-not-available/" + id,
      method: "DELETE",
    });

    return response.data;
  },

  get_s3_link: async function (body) {
    const response = await api.request({
      url: "/get-s3-link",
      method: "POST",
      data: body,
    });

    return response.data;
  },

  search: async function (id, text) {
    const response = await api.request({
      url: `/search-request`,
      method: "GET",
      params: {
        service_id: id,
        query: text,
      },
    });

    return response.data;
  },

  search_message: async function (id, text) {
    const response = await api.request({
      url: `/search-message`,
      method: "GET",
      params: {
        service_id: id,
        query: text,
      },
    });

    return response.data;
  },

  search_payment: async function (id, text) {
    const response = await api.request({
      url: `/search-payment`,
      method: "GET",
      params: {
        service_id: id,
        query: text,
      },
    });

    return response.data;
  },
};
