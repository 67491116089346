import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ProtectedRoute from "./util/ProtectedRoute";
import reportWebVitals from "./reportWebVitals";
import Login from "./Pages/Auth/Login"; 
import NotFound from "./Pages/NotFound"; 
import Dashboard from "./Dashboard";
import IndexServiceRequest from "./Pages/ServiceRequest/Index";
import EditServiceRequest from "./Pages/ServiceRequest/Edit";
import IndexServiceRequestMessage from "./Pages/ServiceRequest/IndexMessage";
import IndexServiceRequestPayment from "./Pages/ServiceRequest/IndexPayment";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />}>
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
 
          <Route
            path="/list-service-request/:service_id"
            element={
              <ProtectedRoute>
                <IndexServiceRequest />
              </ProtectedRoute>
            }
          /> 
          <Route
            path="/list-service-request-message/:service_id"
            element={
              <ProtectedRoute>
                <IndexServiceRequestMessage />
              </ProtectedRoute>
            }
          /> 
          <Route
            path="/list-service-request-payment/:service_id"
            element={
              <ProtectedRoute>
                <IndexServiceRequestPayment />
              </ProtectedRoute>
            }
          /> 
          <Route
            path="/service-request/:id"
            element={
              <ProtectedRoute>
                <EditServiceRequest />
              </ProtectedRoute>
            }
          /> 
     
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
