import React from "react";
import ShareLinkHeader from "./ShareLinkHeader";

function LowerHeader(props) {
  //props needed links[] description
  return (
    <div className="it-nav-wrapper">
      <div className="it-header-center-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="it-header-center-content-wrapper">
                <div className="it-brand-wrapper">
                  <a href="/">
                    <svg className="icon" aria-hidden="true">
                      <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-pa"></use>
                    </svg>
                    <div className="it-brand-text">
                      <div className="it-brand-title">{props.description}</div>
                      <div className="it-brand-tagline d-none d-md-block">Portale servizi</div>
                    </div>
                  </a>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-header-navbar-wrapper" id="header-nav-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="navbar navbar-expand-lg has-megamenu">
                <button
                  className="custom-navbar-toggler"
                  type="button"
                  aria-controls="nav4"
                  aria-expanded="false"
                  aria-label="Mostra/Nascondi la navigazione"
                  data-bs-target="#nav4"
                  data-bs-toggle="navbarcollapsible"
                >
                  <svg className="icon">
                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-burger"></use>
                  </svg>
                </button>
                <div className="navbar-collapsable" id="nav4">
                  <div className="overlay" style={{ display: "none" }}></div>
                  <div className="close-div">
                    <button className="btn close-menu" type="button">
                      <span className="visually-hidden">
                        Nascondi la navigazione
                      </span>
                      <svg className="icon">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-close-big"></use>
                      </svg>
                    </button>
                  </div>
                  <div className="menu-wrapper">
                    <a href={props.siteUrl} className="logo-hamburger">
                      <svg className="icon" aria-hidden="true">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-pa"></use>
                      </svg>
                      <div className="it-brand-text">
                        <div className="it-brand-title">
                          {props.description}
                        </div>
                      </div>
                    </a>
                 {/*   <nav aria-label="Principale">
                      <ul className="navbar-nav" data-element="main-navigation">
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={props.siteUrl + "/amministrazione"}
                            data-element="management"
                          >
                            <span>Amministrazione</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={props.siteUrl + "/novita"}
                            data-element="news"
                          >
                            <span>Novità</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={props.siteUrl + "/servizi"}
                            data-element="all-services"
                          >
                            <span>Servizi</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={props.siteUrl + "/vivere-il-comune"}
                            data-element="live"
                          >
                            <span>Vivere il Comune</span>
                          </a>
                        </li>
                      </ul>
                    </nav>*/}
                    <nav aria-label="Secondaria">
                 
                    </nav>
      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LowerHeader;
