import React from "react"; 
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from "design-react-kit";

export default function UserProfile(props) {
    const navigate = useNavigate();

    const logout = () => {
      localStorage.removeItem("access_token");
      navigate("/login",{replace: "true"});
    }

    return (
        <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
        <span className="rounded-icon" aria-hidden="true">
            <Icon icon="it-user" color="white" /> {" "}
          
          </span>  {props.auth?.name}
        </Dropdown.Toggle>
  
        <Dropdown.Menu> 
          <Dropdown.Item onClick={logout} style={{color: "black",textAlign: "center"}}>Esci</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> );
}

